import React from 'react'
import Helmet from 'react-helmet'
import { Container, Row } from 'reactstrap'

import Layout from 'layouts/static'

import Main from 'components/Main'
import H2 from 'components/H2'
import H3 from 'components/H3'
import H4 from 'components/H4'

import Wrapper from 'views/contact-success/Wrapper'

const ContactSuccessPage = () => {
  return (
    <Layout variant="secondary">
      <Helmet title="wiadomość wysłana" />

      <Main>
        <Wrapper>
          <Container>
            <Row>
              <H2 className="col-12">Mamy to!</H2>
            </Row>
            <Row>
              <H3 className="col-12">
                Świetnie, że do nas napisałeś. Twoja wiadomość doszła do nas
                cała i zdrowa.
                <br />
                <small>
                  Musisz dać nam teraz chwilę czasu na odpowiedź. Jak długą? W
                  dniach roboczych całkiem błyskawiczną.
                  <br />W weekendy i święta będziesz musiał chwilę poczekać. Ale
                  nie będą to wieczności.
                </small>
              </H3>
              <H4 className="col-12 mt-5">
                Jest dzień roboczy?
                <br />
                Sprawdź nasze portfolio, jeśli jeszcze tego nie robiłeś.
                <br />
                <br />
                <br />
                Jest weekend lub święto?
                <br />
                To może potrwać zdecydowanie dłużej, niż 24 godziny. Mamy więc
                trochę czasu w zapasie.
              </H4>
            </Row>
          </Container>
        </Wrapper>
      </Main>
    </Layout>
  )
}

export default ContactSuccessPage
